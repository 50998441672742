import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import {
  RouterProvider, useNavigate,
} from "react-router-dom";
import i18n from "i18next";
import { router } from './route';
import { ThemeProvider } from "@mui/material/styles";
import theme from './assets/theme';
import { Provider, useDispatch } from 'react-redux';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import ApiSchoolService from './Api/ApiSchoolService/apiSchoolService';
import axios from 'axios';
import { setValueStaff } from './store/staff';

function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
