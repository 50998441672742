import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { role } from '../../data/data';

interface ISelect {
    name: string;
    list: any[];
    onChange: (e:any) => void;
    value: number;
}
const SelectForm: React.FC<ISelect> = ({name, list, onChange, value}) => {
    // const handleChange = (event:any) => {
    //   setAge(event.target.value);
    // };
    return (
        <FormControl variant="standard" sx={{ m: 1, width: '100%'}}>
            <InputLabel id="demo-simple-select-standard-label">{name}</InputLabel>
            <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={value}
            onChange={onChange}
            label="Age"
            >
            <MenuItem value="">
                <em>None</em>
            </MenuItem>
                {list.map(elem => (
                    <MenuItem key={elem.id} value={elem.id}>{elem.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectForm
