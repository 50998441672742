import { Typography } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ApiSchoolService from '../../Api/ApiSchoolService/apiSchoolService';
import { AlertNotif } from '../../components/Alert';
import { BasincInfo, BasincInfoSchool } from '../../components/Card';
import data from '../../data/fr.json';
import { updateDataSchool, updateDataUser } from '../../store/user';

const api = new ApiSchoolService();
export default function Settings() {
  const user = useSelector((state: any) => state.user.user);
  const school = useSelector((state: any) => state.user.school);
  const [userState, setUserState] = useState(user);
  const [schoolState, setSchoolState] = useState(school);
  const [alert, setAlert] = useState(false);
  const dispatch = useDispatch();

  const updateInfoUser = () => {
    api.updateInfoStaff(userState)
    .then(() => {
      setAlert(true);
      dispatch(updateDataUser(userState));
      setTimeout(()=> {
        setAlert(false);
      }, 3000)
    })
    .catch(e => {
      console.log(e.response.data);
    })
  }
  
  const updateInfoSchool = () => {
    api.updateInfoSchol(schoolState)
    .then(() => {
      setAlert(true);
      dispatch(updateDataSchool(schoolState));
      setTimeout(()=> {
        setAlert(false);
      }, 3000)
    })
    .catch(e => {
      console.log(e.response.data);
    })
  }
  return (
    <div>
      <Typography variant="h4">{data.setting}</Typography>
      {
        alert ? <AlertNotif title={data.updateInfoSuccess} desc={data.updateInfoSuccessDesc}/> : null
      }
      <BasincInfo info={userState} onClick={updateInfoUser} setInfo={setUserState}/>
      {
        user.role === 0 ? 
        <BasincInfoSchool info={schoolState} onClick={updateInfoSchool} setInfo={setSchoolState}/>
        : null
      }
    </div>
  )
}
