import { Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import MDAvatar from '../MDAvatar'
import MDBox from '../MDBox'
import MDTypography from '../MDTypography';

interface IHeader {
    title: string;
    desc?:  string
}
const Header: React.FC<IHeader> = ({title, desc}) => {
    const navigate = useNavigate();
  return (
    <MDBox display='flex' justifyContent="space-between" alignItems="center" mb={5}>
      <div>
        <Typography variant="h4">{title}</Typography>
        <MDTypography variant="button" color="text">
          {desc} 
        </MDTypography>
      </div>
      <MDAvatar src="https://storage.googleapis.com/tank-files-upload/avatar-1.png" alt="Avatar" onClick={() => {
          navigate('/setting');
      }}/>
    </MDBox>
  )
}

export default Header;