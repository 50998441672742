import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import Root from './routes/root';
import ErrorPage from './routes/error-page';
import { Login } from "./screens/Login";
import { Dashboard } from "./screens/Dashboard";
import { Invitation } from "./screens/Invitation";
import { Recommand } from "./screens/Recommand";
import { Ats } from "./screens/ats";
import { Settings } from "./screens/Settings";
import { Account } from "./screens/AccountProf";

export const router = createBrowserRouter([
    {
        path: '/login',
        element: <Login />
    },
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
          {
              path: 'dashboard',
              element: <Dashboard />
          },
          {
              path: 'invit',
              element: <Invitation />
          },
          {
              path: 'recommandation',
              element: <Recommand />
          },
          {
              path: 'gestion',
              element: <Ats />
          },
          {
              path: 'setting',
              element: <Settings />
          },
          {
              path: 'account-prof',
              element: <Account />
          },
      ]
    }
  ]);