import { createSlice} from "@reduxjs/toolkit";
import { IStaff } from "../../Api/ApiSchoolService/types";
import { IRow } from "../../screens/Invitation/types";

type Student = {
    studentListInvitInProgress: IRow[]
    studentListInvitAccept: IRow[],
    centerPartenariat: any[],
}

const initialState: Student = {
    studentListInvitInProgress: [],
    studentListInvitAccept: [],
    centerPartenariat: []
}

export const studentSlice = createSlice({
    name: "student",
    initialState,
    reducers: {
        setValueStudent: (state, action) => {
           state.studentListInvitInProgress = action.payload.filter((elem: any) => elem.status === 0);
           state.studentListInvitAccept = action.payload.filter((elem: any) => elem.status === 1);
        },
        pushStudentList: (state, action: {type: string, payload: IRow}) => {
            state.studentListInvitInProgress.push(action.payload);
        },
        deleteStudentFromList: (state, action) => {
            state.studentListInvitInProgress = state.studentListInvitInProgress.filter(elem => elem.id !== action.payload)
        },
        concatStudentList: (state, action) => {
            state.studentListInvitInProgress = action.payload;
        },
        setValueCenter: (state, action) => {
            state.centerPartenariat = action.payload;
        }
    }
})

export const {
    setValueStudent,
    pushStudentList,
    deleteStudentFromList,
    concatStudentList,
    setValueCenter
} = studentSlice.actions;