import { BASE_URL } from "../API_URL";
import RequestServices from "../RequestServices";
import { IAuthCandidat } from "./types";

class ApiAuthService {
    postLogin(data:IAuthCandidat){
        let url = `${BASE_URL}/auth-school/login`;
        return RequestServices.postRequest(url, data);
    }

    
}
export default ApiAuthService;