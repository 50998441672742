import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./user";
import { persistReducer, persistStore } from 'redux-persist';
import storage from "redux-persist/es/storage";
import { staffSlice } from "./staff";
import { studentSlice } from "./student";

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({ 
    user: userSlice.reducer,
    staff: staffSlice.reducer,
    student: studentSlice.reducer,
  })
  
const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
    reducer: persistedReducer
})

export const persistor = persistStore(store);