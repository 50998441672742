/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Card, Grid, TextField } from '@mui/material'

// Material Dashboard 2 PRO React components
import MDInput from "../MDInput";

interface IField {
  label: string;
  placeholder: string;
  value: string;
  onChange: (e: any) => void;
  disable?: boolean
}
const FormField: React.FC<IField> = ({ label, placeholder, value, onChange, disable = false}) => {
  return (
    <TextField
    disabled= {disable}
    id="standard-basic"
    label={label}
    variant="standard"
    placeholder={placeholder}
    onChange={onChange}
    value={value}
    sx={{ m: 1, width: '100%' }}   
    />
  );
}

// Setting default values for the props of FormField
export default FormField;
