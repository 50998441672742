import { createSlice} from "@reduxjs/toolkit";
import { IStaff } from "../../Api/ApiSchoolService/types";

type Staff = {
    staffList: IStaff[]
}

const initialState: Staff = {
    staffList: []
}

export const staffSlice = createSlice({
    name: "staff",
    initialState,
    reducers: {
        setValueStaff: (state, action) => {
           state.staffList = action.payload;
        },
        pushStaffList: (state, action: {type: string, payload: IStaff}) => {
            state.staffList.push(action.payload);
        },
        deleteStaffFromList: (state, action) => {
            state.staffList = state.staffList.filter(elem => elem.id !== action.payload)
         },
    }
})

export const {
    setValueStaff,
    pushStaffList,
    deleteStaffFromList
} = staffSlice.actions;