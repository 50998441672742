import { Card, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MDTypography from '../MDTypography';
import data from '../../data/fr.json';
import MDBox from '../MDBox';
import FormField from '../Form/Form';
import SelectForm from '../Form/SelectForm';
import { role } from '../../data/data';
import MDButton from '../MDButton';
import { useDispatch, useSelector } from 'react-redux';
import ApiSchoolService from '../../Api/ApiSchoolService/apiSchoolService';
import { IStaff } from '../../Api/ApiSchoolService/types';
import { pushStaffList } from '../../store/staff';

interface ICreateAccount {
    open: boolean;
    onClose: () => void;
}

const makeAccountEmpty = () => {
    let data: IStaff = {
        firstName: "",
        lastName: "",
        role: 0,
        mail: "",
        password: "",
        school: 0
    }

    return data;
}

const CreateAccount: React.FC<ICreateAccount> = ({open, onClose}) => {
    const [account, setAccount] = useState<IStaff>(makeAccountEmpty);
    const school = useSelector((state: any) => state.user.school);
    const dispatch = useDispatch();

    const postLogin = () => {
        const api = new ApiSchoolService();
        console.log(account);
        
        api.createAccountStaff(account).then(res => {
            dispatch(pushStaffList(res.data));
            onClose();
        }).catch(e => {
            console.log(e.response.data);
        })
    }

    useEffect(()=>{
        console.log(school);
        
        setAccount(old => ({...old, school: Number(school.id)}))
    },[])
    return (
        <Card id="basic-info" sx={{ overflow: "visible" }} style={{border: "1px solid #000000", marginTop: "20vh", borderRadius:"12px", padding: '3vh', width:'100vh'}}>
            <MDTypography variant="h5">{data.newAccount}</MDTypography>
            <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={4}>
                <FormField 
                    label={data.name}
                    value={account.lastName}
                    placeholder={data.name}
                    onChange={(e) => {
                    setAccount(old => ({...old, lastName: e.target.value}))
                    }}
                    />
                </Grid>
            <Grid item xs={12} sm={4}>
                <FormField 
                label={data.firstName}
                value={account.firstName}
                placeholder={data.firstName}
                onChange={(e) => {
                    setAccount(old => ({...old, firstName: e.target.value}))
                }}
                />
                </Grid>
                <Grid item xs={12} sm={4}>
                <FormField 
                    label={data.mail}
                    value={account.mail}
                    placeholder={data.mail}
                    onChange={(e) => {
                    setAccount(old => ({...old, mail: e.target.value}))
                    }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                <FormField 
                    label={data.password}
                    value={account.password}
                    placeholder={data.password}
                    onChange={(e) => {
                    setAccount(old => ({...old, password: e.target.value}))
                    }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                <SelectForm 
                    name={data.role} 
                    list={role}
                    value={account.role}
                    onChange={(e) => {
                        setAccount(old => ({...old, role: e.target.value}))
                    }} 
                />
                </Grid>
            </Grid>
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end" flexWrap="wrap" mt={5}>
                <MDBox mr={2}>
                    <MDButton variant="gradient" color="dark" size="small" onClick={postLogin}>
                    {data.makeAccount}
                    </MDButton>
                </MDBox>
                    <MDButton variant="gradient" color="dark" size="small" onClick={onClose}>
                    {data.close}
                    </MDButton>
            </MDBox>
        </Card>
    )
}

export default CreateAccount;