import MDTypography from "../components/MDTypography";
import { IRole, role, typeMedicalCenter } from "../data/data";
import { IRow } from "../screens/Invitation/types";

export const alertContent = (name: string) => (
    <MDTypography variant="body2" color="white">
      {name}
    </MDTypography>
  );

export const getStatusRole = (id: number) => {
  const roleStatus= role.find(elem => elem.id === id);
  if(roleStatus)
    return roleStatus.name;
}

export const addStudentList = (cell: IRow, elem: IRow[], func: React.Dispatch<any>) => {
  const tabCell = elem.find(elem => elem.id === cell.id)
  if(tabCell){
    func(elem.filter(elem => elem.id !== cell.id));
  } else {
    func([...elem, cell]);
  }
}

export const gettypeMedicalCenterByProfession = (profession: number) => {
  const type = typeMedicalCenter.find(elem => elem.id === profession);
  return type!.name;
}