import { IRow } from "../../screens/Invitation/types";
import { BASE_URL } from "../API_URL";
import RequestServices from "../RequestServices";
import { ISchool, IStaff } from "./types";

class ApiSchoolService {
    createAccountStaff(data: IStaff){
        let url = `${BASE_URL}/school/createStaff`;
        return RequestServices.postRequest(url, data);
    }

    getInfoStaffBySchoolId(){
        let url = `${BASE_URL}/school/getInfoStaffById`;
        return RequestServices.getRequest(url);
    }

    deleteStaffAccount(id: number){
        let url = `${BASE_URL}/school/deleteInfo/${id}`;
        return RequestServices.deleteRequest(url);
    }

    updateInfoStaff(data: IStaff){
        let url = `${BASE_URL}/school/updateInfoStaff`;
        return RequestServices.putRequest(url, data);
    }
   
    updateInfoSchol(data: ISchool){
        let url = `${BASE_URL}/school/updateInfoSchool`;
        return RequestServices.putRequest(url, data);
    }

    sendInvitation(data: IRow[]){
        let url = `${BASE_URL}/school/createInvit`;
        return RequestServices.postRequest(url, data)
    }

    getInvitationBySchoolId(){
        let url = `${BASE_URL}/school/getInvitBySchoolId`;
        return RequestServices.getRequest(url);
    }

    getCenterPartenariat(){
        let url = `${BASE_URL}/school/getCenterPartnaire`;
        return RequestServices.getRequest(url);
    }
}

export default ApiSchoolService;