// import { Typography } from '@mui/material'
import React from 'react'
import Header from '../../components/Header';
import dataJson from '../../data/fr.json';
// import Box from '@mui/material/Box';
// import { DataGridPro, GridRow, GridColumnHeaders } from '@mui/x-data-grid-pro';
// import { useDemoData } from '@mui/x-data-grid-generator';
import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import { Box, Icon, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CreateAccount from '../../components/Modal/createAccount';
import { useDispatch, useSelector } from 'react-redux';
import { IStaff } from '../../Api/ApiSchoolService/types';
import { getStatusRole } from '../../utils/utils';
import { deleteStaffFromList } from '../../store/staff';
import ApiSchoolService from '../../Api/ApiSchoolService/apiSchoolService';
import { AlertNotif } from '../../components/Alert';
import { COLOR_RED } from '../../components/color';

export default function Account() {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const staff = useSelector((state: any) => state.staff.staffList);
  const dispatch = useDispatch();

  const deleteStaff = (id: number) => {
    if(window.confirm(dataJson.sureToDeleteAccount)){
      const api = new ApiSchoolService();
      api.deleteStaffAccount(id)
      .then(() => {
        dispatch(deleteStaffFromList(id));
        setAlert(true);
        setTimeout(()=> {
          setAlert(false);
        }, 3000)
      }).catch(e => {
        console.log(e.response.data);
        
      })
    }
  }
  return (
    <div style={{height:'65vh'}}>
        <Header title={dataJson.createAccount} desc={dataJson.descAccount}/>
        {
          alert ? <AlertNotif title={dataJson.deleteAlert} desc={dataJson.deleteStaffDesc}/> : null
        }
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" mt={10}>
              <MDBox ml="auto">
                <MDButton variant="gradient" color="dark" size="small" onClick={handleOpen}>
                  {dataJson.makeAccount}
                </MDButton>
              </MDBox>
          </MDBox>
          <MDBox mt={5}>
            <Typography variant="h4">{dataJson.manageAccount}</Typography>
            <TableContainer component={Paper} style={{marginTop: '3vh', borderStyle: "solid", borderWidth:1}}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{dataJson.name}</TableCell>
                    <TableCell align="right">{dataJson.firstName}</TableCell>
                    <TableCell align="right">{dataJson.mail}</TableCell>
                    <TableCell align="right">{dataJson.role}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staff.map((row: IStaff, index: number) => (
                    <TableRow
                      key={row.id}
                    >
                      <TableCell component="th" scope="row">
                        {row.lastName}
                      </TableCell>
                      <TableCell align="right">{row.firstName}</TableCell>
                      <TableCell align="right">{row.mail}</TableCell>
                      <TableCell align="right">{getStatusRole(row.role)}</TableCell>
                      <TableCell><Icon fontSize="small" onClick={() => {deleteStaff(row.id!)}} color={"error"}>delete</Icon></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MDBox>
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox display='flex' justifyContent= 'center'>
        <CreateAccount open={open} onClose={handleClose}/>
      </MDBox>
    </Modal>
    </div>
  )
}
