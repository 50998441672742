import { BASE_URL } from "../API_URL";
import RequestServices from "../RequestServices";

class ApiUserService {
    getInfoUser() {
        let url = `${BASE_URL}/auth-school/status`
        return RequestServices.getRequest(url);
    }
}

export default ApiUserService;