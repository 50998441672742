import React from 'react'
import { Card, Grid, TextField } from '@mui/material'
import MDBox from '../MDBox'
import MDTypography from '../MDTypography'
import MDAvatar from '../MDAvatar'
import { gettypeMedicalCenterByProfession } from '../../utils/utils'

interface ICenter {
    elem: any;
}
const Center: React.FC<ICenter> = ({elem}) => {
    console.log(elem);
    const {center} = elem;
  return (
    <Card 
    id="basic-info"
    sx={{ overflow: "visible" }} style={{border: "1px solid #000000", borderRadius:"12px", marginBottom: '5px'}}
    onClick={() => {alert('ok')}}
    >
        <MDBox component="form" pb={3} px={2}>
            <MDBox display="flex" alignItems="center" mt={2}>
            <MDAvatar src={center.profilCenterPicture ? 
                center.profilCenterPicture 
                : "https://storage.googleapis.com/tank-files-upload/avatar-1.png"} alt="Avatar"/>
                <MDBox ml={2}>
                    <MDTypography>{center.name}</MDTypography>
                    <MDTypography variant='button' color="text">{gettypeMedicalCenterByProfession(center.profession)}</MDTypography>
                </MDBox>
            </MDBox>
            <MDBox component="form" mt={5}>
                <MDTypography variant='button' >{center.adress}, {center.postCode}, {center.city}</MDTypography>
            </MDBox>
        </MDBox>
    </Card>
  )
}

export default Center;