import { Card, Grid, TextField } from '@mui/material'
import React from 'react'
import FormField from '../../Form/Form'
import MDBox from '../../MDBox'
import MDButton from '../../MDButton'
import data from '../../../data/fr.json';
import MDTypography from '../../MDTypography'
import { IBasicInfo } from './types'
import { getStatusRole } from '../../../utils/utils'
import { IStaff } from '../../../Api/ApiSchoolService/types'


const BasincInfo: React.FC<IBasicInfo> = ({info, onClick, setInfo}) => {
  const {firstName, lastName, mail, role} = info;
  return (
    <>
     <MDBox p={1} mt={3}>
        <MDTypography variant="h5">{data.infoUser}</MDTypography>
        <MDTypography variant="button" color="text">
          {data.descBasicInfo} 
        </MDTypography>
      </MDBox>
      <Card id="basic-info" sx={{ overflow: "visible" }} style={{border: "1px solid #000000", borderRadius:"12px"}}>
        <MDBox component="form" pb={3} px={3}>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={4}>
              <FormField 
                label={data.name}
                value={lastName}
                placeholder={data.name}
                onChange={(e) => {
                  setInfo((old: IStaff) => ({...old, lastName: e.target.value}))
                }}
                />
            </Grid>
          <Grid item xs={12} sm={4}>
              <FormField 
              label={data.firstName}
              value={firstName}
              placeholder={data.firstName}
              onChange={(e) => {
                setInfo((old: IStaff) => ({...old, firstName: e.target.value}))
              }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField 
                label={data.mail}
                value={mail}
                placeholder={data.mail}
                onChange={(e) => {
                  setInfo((old: IStaff) => ({...old, mail: e.target.value}))
                }} />
              </Grid>
              <Grid item xs={12} sm={4}>
              <FormField 
              label={data.role}
              value={getStatusRole(role)!}
              placeholder={data.role}
              disable={true}
              onChange={(e) => {}}/>
            </Grid>
          </Grid>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" mt={10}>
              <MDBox ml="auto">
                <MDButton variant="gradient" color="dark" size="small" onClick={onClick}>
                  {data.updateInfo}
                </MDButton>
              </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </>
  )
}

export default BasincInfo;
