import { createSlice} from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {},
        school: {},
    },
    reducers: {
        setValueUser: (state, action) => {
           state.user = action.payload;
           state.school = action.payload.school;
        },
        updateDataUser: (state, action) => {
            state.user = action.payload;        
        },
        updateDataSchool: (state, action) => {
            state.school = action.payload;        
        }
    }
})

export const {
    setValueUser,
    updateDataUser,
    updateDataSchool
} = userSlice.actions;