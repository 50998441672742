export interface IRole {
    id: number,
    name: string
}

export const role: IRole[] = [
    {
        id: 0,
        name: 'Admin'
    },
    {
        id: 1,
        name: 'professeur'
    }
]

export const status = [
  {
    id: 0,
    name: 'En cours'
  },
  {
    id: 1,
    name: 'Inscrit'
  },
  {
    id: 2,
    name: 'Refusé'
  },
]

export const typeMedicalCenter = [
  {
      id: 0,
      name: "Centre de santé",
  },
  {
      id: 1,
      name: "Maison de santé",
  },
  {
      id: 2,
      name: "Cabinet",
  },
]