import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Grid, Typography } from '@mui/material';
import MDBox from '../MDBox';
import ComplexStatisticsCard from '../../examples/Cards/StatisticsCards/ComplexStatisticsCard';
import data from '../../data/fr.json';
import Center from '../Center/center';
import MDButton from '../MDButton';
import { useSelector } from 'react-redux';
import { IRow } from '../../screens/Invitation/types';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const list = (anchor: Anchor, state: any, setState: React.Dispatch<any>, center: any, listStudent:IRow[]) => {
  console.log("center: ",center);
  
  const recommandCandidat = () => {
    if(listStudent.length === 0) {
      return alert(data.noSelectStudent);
    }
    console.log(listStudent);
  }
  return(
    <Box onKeyDown={toggleDrawer(anchor, false, state, setState)}
    >
      <Typography variant="h4">{data.partnerCenter}</Typography>
      <Grid item xs={12} md={6} lg={4} mt={2}>
        <MDBox mb={5}>
          {
            center && center.map((elem: any) => (
              <Center elem={elem}/>
            ))
          }
        </MDBox>
      </Grid>
      <MDBox display="flex" justifyContent="center">
        <MDButton variant="gradient" color="dark" size="small" onClick={recommandCandidat}>
          {data.recommandation}
        </MDButton>
      </MDBox>
    </Box> )
};

export const toggleDrawer =
(anchor: Anchor, open: boolean, state: any, setState: React.Dispatch<any>) =>
(event: React.KeyboardEvent | React.MouseEvent) => {
  if (
    event.type === 'keydown' &&
    ((event as React.KeyboardEvent).key === 'Tab' ||
      (event as React.KeyboardEvent).key === 'Shift')
  ) {
    return;
  }

  setState({ ...state, [anchor]: open });
};