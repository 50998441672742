import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate } from "react-router-dom";
import data from '../data/fr.json';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';

import tank from '../assets/image/tank.png';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import ApiSchoolService from "../Api/ApiSchoolService/apiSchoolService";
import { setValueStaff } from "../store/staff";
import { setValueCenter, setValueStudent } from "../store/student";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';

export default function Root() {
    const user = useSelector((state: any) => state.user.user);

    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=> {
      const token = localStorage.getItem('userToken');    
      if(!token) {
        navigate(`/login`);
      } else {
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        const apiSchool = new ApiSchoolService();
        apiSchool.getInfoStaffBySchoolId()
        .then(res => {
          dispatch(setValueStaff(res.data));
        })
        .catch(e => {
          console.log(e.response.data);
        })

        apiSchool.getInvitationBySchoolId().then((res) => {
          // setInvit(res.data);
          dispatch(setValueStudent(res.data));
        }).catch(e => {
          console.log(e.response.data);
        })

        apiSchool.getCenterPartenariat().then((res) => {
          dispatch(setValueCenter(res.data));
          console.log(res.data);
          
        }).catch(e => {
          console.log(e.response.data);
        })

      }
    }, [])

    const logout = () => {
      localStorage.removeItem('userToken');
      localStorage.removeItem('persist:root');
      navigate("/login");
    }
    return (
      <>
        <div id="sidebar">
          <div>
            <Link to={`dashboard`}> <img src={tank} alt={data.title}></img></Link>
          </div>
          <nav>
            <ul>
              <li>
                <div style={{alignItems: 'flex-start'}}>
                  <Link to={`dashboard`}><HomeIcon fontSize="small"/>{data.dashboard}</Link>
                </div>
              </li>
              <li>
                <Link to={`invit`}><EmailIcon fontSize="small"/>{data.invit}</Link>
              </li>
              <li>
                <Link to={`recommandation`}><PeopleAltIcon fontSize="small"/>{data.student}</Link>
              </li>
              {/* <li>
                <Link to={`gestion`}>{data.ats}</Link>
              </li> */}
              {
                user.role === 0 ? 
                <li>
                  <Link to={`account-prof`}><GroupAddIcon fontSize="small"/>{data.accountProf}</Link>
                </li>
                :null
              }
              <li>
                <Link to={`setting`}><ManageAccountsIcon fontSize="small"/>{data.setting}</Link>
              </li>
            </ul>
          </nav>
          <Button variant="text" color="error" onClick={logout}><Icon fontSize="medium" color="inherit">
            logout
        </Icon>{data.logout}</Button>
        </div>
        <div id="detail">
            <Outlet />
        </div>
      </>
    );
  }