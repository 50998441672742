import { Grid, Typography } from '@mui/material';
import data from '../../data/fr.json';
import { useDispatch, useSelector } from "react-redux";
import MDBox from '../../components/MDBox';
import ComplexStatisticsCard from '../../examples/Cards/StatisticsCards/ComplexStatisticsCard';
import Header from '../../components/Header';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import MDTypography from '../../components/MDTypography';
import MDButton from '../../components/MDButton';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';

const status = [
  {
    id: 0,
    name: 'En cours'
  },
  {
    id: 1,
    name: 'Inscrit'
  },
  {
    id: 2,
    name: 'Refusé'
  },
]

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', mail: 'r.jaoui201@gmail.com', status: 0 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', mail: 'r.jaoui201@gmail.com', status: 1 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', mail: 'r.jaoui201@gmail.com', status: 0 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', mail: 'r.jaoui201@gmail.com', status: 0 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', mail: 'r.jaoui201@gmail.com', status: 1 },
//   { id: 6, lastName: 'Melisandre', firstName: null, mail: 'r.jaoui201@gmail.com', status: 0 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', mail: 'r.jaoui201@gmail.com', status: 2 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', mail: 'r.jaoui201@gmail.com', status: 2 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', mail: 'r.jaoui201@gmail.com', status: 0 },
// ];

const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', width:200},
  { field: 'firstName', headerName: 'Nom', width:200},
  { field: 'lastName', headerName: 'Prénom', width:200},
  {
    field: 'mail',
    headerName: 'Mail',
    width:200
  },
  {
    field: 'status',
    headerName: 'Status inscription',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const statusFilter = status.find(elem => elem.id === params.row.status)
      return statusFilter!.name
    },
    width:200,
  },
];

export default function Dashboard() {
  const invit = useSelector((state: any) => state.student.studentListInvitInProgress);
  const invitAccept = useSelector((state: any) => state.student.studentListInvitAccept);
  const navigate = useNavigate();

  console.log('invit dash',invit);
  

  return (
    <div>
      <Header title={data.dashboard} />
      <MDBox>
        <Grid container spacing={5} mb={5} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="people"
                    title={data.student}
                    count={invitAccept.length}
                  />
              </MDBox>
            </Grid>
          <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="work"
                    title={data.workFind}
                    count="0"
                  />
              </MDBox>
            </Grid>
          <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title={data.invit}
                    count={invit.length}
                  />
              </MDBox>
            </Grid>
        </Grid>
        <Typography variant="h4">{data.invitInProgress}</Typography>
        <MDTypography variant="button" color="text">
            {data.invitInProgressDesc} 
        </MDTypography>
        <div style={{height:'42.8vh'}}>
          <DataGrid
            sx={{ border: 1, borderRadius:3}}
            rows={invit.slice(0,5).reverse()}
            columns={columns}
            autoPageSize= {true}
            disableRowSelectionOnClick
            style={{backgroundColor: "white"}}
            />
        </div>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" mt={5}>
          <MDBox ml="auto">
              <MDButton variant="gradient" color="dark" size="small" onClick={() => {
                navigate(`/recommandation`);
              }}>
                {data.seeMore}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
    </div>
  )
}
