import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { AppBar, Drawer, Grid, Icon, Tab, Tabs, Typography } from '@mui/material';
import data from '../../data/fr.json';
import Header from '../../components/Header';
import breakpoints from '../../assets/theme/base/breakpoints';
import axios from 'axios';
import ApiSchoolService from '../../Api/ApiSchoolService/apiSchoolService';
import { IRow } from '../Invitation/types';
import { useSelector } from 'react-redux';
import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import { addStudentList } from '../../utils/utils';
import Footer from '../../components/Footer';
import { list, toggleDrawer } from '../../components/Drawer';
import { status } from '../../data/data';



const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', width:200},
  { field: 'firstName', headerName: 'Nom', width:200},
  { field: 'lastName', headerName: 'Prénom', width:200},
  {
    field: 'mail',
    headerName: 'Mail',
    width:200
  },
  {
    field: 'status',
    headerName: 'Status inscription',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const statusFilter = status.find(elem => elem.id === params.row.status)
      return statusFilter!.name
    },
    width:200,
  },
];

// const api = new ApiSchoolService();
export default function Recommand() {
  const center = useSelector((state: any) => state.student.centerPartenariat);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [studentSelect, setStudentSelect] = useState<IRow[]>([]);
  const [resendInvit, setResendInvit] = useState<IRow[]>([]);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const invit = useSelector((state: any) => state.student.studentListInvitInProgress);
  const invitAccept = useSelector((state: any) => state.student.studentListInvitAccept);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
}, [tabsOrientation]);

const handleSetTabValue = (event:any, newValue:any) => setTabValue(newValue);

  return (
    <div style={{height:'80vh'}}>
      <Header title={data.student} desc={data.descRecommand}/>
      <Grid item mt={5} xs={12} md={6} lg={4} sx={{ mr: "auto" }}>
        <AppBar position="static">
              <Tabs orientation={"horizontal"} value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    label={data.recommand}
                    icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>people</Icon>}
                  />
                  <Tab
                    label={data.invit}
                    icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>mail</Icon>}
                  />
                </Tabs>
          </AppBar>
        </Grid>
      {tabValue === 0 ?
        <div style={{paddingTop:'2%', height:'50vh'}}>
          <DataGrid
            sx={{ border: 1, borderRadius:3}}
            style={{backgroundColor: "white"}}
            rows={invitAccept}
            columns={columns}
            checkboxSelection
            pageSizeOptions={[5]}
            autoPageSize= {true}
            onCellClick={(e) => {
              addStudentList(e.row, studentSelect!, setStudentSelect);
            }}
            />
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" mt={5}>
            <MDBox ml="auto">
                <MDButton variant="gradient" color="dark" size="small" onClick={toggleDrawer("right", true, state, setState)}>
                  {data.recommandation}
                </MDButton>
              </MDBox>
          </MDBox>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false, state, setState)}
            PaperProps={{sx: {width: 400, padding:"15px"}}}
          >
            {list("right", state, setState, center, studentSelect)}
          </Drawer>
        </div> :  
        <div style={{paddingTop:'2%', height:'50vh'}}>
          <DataGrid
            sx={{ border: 1, borderRadius:3}}
            style={{backgroundColor: "white"}}
            rows={invit}
            columns={columns}
            checkboxSelection
            pageSizeOptions={[5]}
            autoPageSize= {true}
            onCellClick={(e) => {
              addStudentList(e.row, resendInvit!, setResendInvit);
            }}
          />
           <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" mt={5}>
            <MDBox ml="auto">
                <MDButton variant="gradient" color="dark" size="small" >
                  {data.resendInvit}
                </MDButton>
              </MDBox>
          </MDBox>
         
        </div>
      }
    </div>
  )
}
