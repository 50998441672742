import React, { useCallback, useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import MDDropzone from '../../components/MDDropzone';
import { Typography } from '@mui/material';
import data from '../../data/fr.json';
import Header from '../../components/Header';
import Dropzone, {useDropzone} from 'react-dropzone'
import { resolve } from 'path';
import * as XLSX from 'xlsx';
import { IExcel, IRow } from './types';
import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import { addStudentList } from '../../utils/utils';
import MDTypography from '../../components/MDTypography';
import ApiSchoolService from '../../Api/ApiSchoolService/apiSchoolService';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { concatStudentList } from '../../store/student';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width:200},
  { field: 'firstName', headerName: 'Nom', width:200},
  { field: 'lastName', headerName: 'Prénom', width:200},
  {
    field: 'mail',
    headerName: 'Mail',
    width:200
  }
];

const api = new ApiSchoolService();
export default function Invitation() {
  const [rowArray, setRowArray] = useState<IRow[] | undefined>(undefined);
  // const [studentSelect, setStudentSelect] = useState<IRow[]>([]);
  const invit = useSelector((state: any) => state.student.studentListInvitInProgress);
  const invitAccept = useSelector((state: any) => state.student.studentListInvitAccept);
  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles: any[]) => {
    acceptedFiles.forEach((file) => {
      const promise = new Promise((resolve, reject) => {

      const reader = new FileReader()
      reader.readAsArrayBuffer(file)   
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = (e) => {
        const bufferArray = e.target?.result;
        const wb= XLSX.read(bufferArray, {type: 'buffer'});
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      }
      })
      promise.then((res: any) => {
          const tab: IRow[] = [];
          const array3 = invit.concat(invitAccept);          
          res.forEach((element: IExcel, index: number) => {
            const findCell = array3.find((elem:any) => elem.mail === element.mail);
            if(!findCell) {
              tab.push({
                id: index,
                firstName: element.prenom,
                lastName: element.Nom,
                mail: element.mail,
                status: 0
              })
            }
          });
          setRowArray(tab);
      });  
    })
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop});
  
  const postInvit = () => {  
    if(window.confirm(data.sureToSendInvit)){  
      const token = localStorage.getItem('userToken');
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};
      api.sendInvitation(rowArray!).then(res => {
        const array3 = invit.concat(rowArray);
        dispatch(concatStudentList(array3));
      }).catch(e => {
        console.log(e.response.data);
      })
    }
  } 
  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <Header title={data.invit} desc={data.descInvit}/>
      <div style={{marginTop:'3%', height: '65vh'}}>
        {
          rowArray ?
          <>
            <Typography variant="h4">{data.list}</Typography>
            <MDTypography variant="button" color="text">
              {data.selectStudent} 
            </MDTypography>
            <DataGrid
              sx={{ border: 1, borderRadius:3}}
              style={{backgroundColor: "white"}}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10
                  }
                }
              }}
              rows={rowArray}
              columns={columns}
              pageSizeOptions={[10]}
              autoPageSize= {true}
              // checkboxSelection
              // onCellClick={(e) => {
              //   addStudentList(e.row, studentSelect!, setStudentSelect);
              // }}
              />
              <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" mt={10}>
                <MDBox ml="auto">
                  <MDButton variant="gradient" color="dark" size="small" onClick={postInvit}>
                    {data.sendInvit}
                  </MDButton>
                </MDBox>
              </MDBox>
          </>: <div className='drop' {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Insérer un fichier excel</p>
          </div>
          } 
      </div>
    </div>
  )
}
