import { Alert, AlertTitle } from '@mui/material'

interface IAlert {
    title: string;
    desc: string
}
const AlertNotif: React.FC<IAlert> = ({title, desc}) => {
  return (
        <Alert severity="success">
            <AlertTitle>{title}</AlertTitle>
           {desc}
        </Alert>
  )
}

export default AlertNotif;