import { Card, Grid, TextField } from '@mui/material'
import React from 'react'
import FormField from '../../Form/Form'
import MDBox from '../../MDBox'
import MDButton from '../../MDButton'
import data from '../../../data/fr.json';
import MDTypography from '../../MDTypography'
import { IBasicInfo } from './types'
import { ISchool } from '../../../Api/ApiSchoolService/types'

const BasincInfoSchool: React.FC<IBasicInfo> = ({info, onClick, setInfo}) => {
  const {name, adress, postCode, city} = info;
  return (
    <MDBox mt={3} mb={5}>
     <MDBox p={1} >
        <MDTypography variant="h5">{data.infoSchool}</MDTypography>
        <MDTypography variant="button" color="text">
          {data.descBasicInfoSchool} 
        </MDTypography>
      </MDBox>
      <Card id="basic-info" sx={{ overflow: "visible" }} style={{border: "1px solid #000000", borderRadius:"12px", marginBottom: '5px'}}>
        <MDBox component="form" pb={3} px={3}>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={4}>
              <FormField 
                label={data.nameSchool}
                value={name}
                placeholder={data.nameSchool}
                onChange={(e) => {
                  setInfo((old: ISchool) => ({...old, name: e.target.value}))
                  console.log(e.target.value);
                  
                }}
                />
            </Grid>
          <Grid item xs={12} sm={4}>
              <FormField 
              label={data.adress}
              value={adress}
              placeholder={data.adress}
              onChange={(e) => {
                setInfo((old: ISchool) => ({...old, adress: e.target.value}))
              }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField 
                label={data.postCode}
                value={postCode}
                placeholder={data.postCode}
                onChange={(e) => {
                  setInfo((old: ISchool) => ({...old, postCode: e.target.value}))                  
                }} />
              </Grid>
              <Grid item xs={12} sm={4}>
              <FormField 
              label={data.city}
              value={city}
              placeholder={data.city}
              onChange={(e) => {
                setInfo((old: ISchool) => ({...old, city: e.target.value}))
              }}/>
            </Grid>
          </Grid>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" mt={10}>
              <MDBox ml="auto">
                <MDButton variant="gradient" color="dark" size="small" onClick={onClick}>
                  {data.updateInfo}
                </MDButton>
              </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  )
}

export default BasincInfoSchool;
