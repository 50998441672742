/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import MDAlert from "../../components/MDAlert";

// Authentication layout components
import IllustrationLayout from "./IllustrationLayout";

// Image
import center from "../../assets/image/center.png";
// import ApiAuthService from "Api/AuthServices/AuthServices";
// import { connect } from "react-redux";
// import { infoUser } from "Stores/Actions/user";
// import jwt from 'jwt-decode'
import jwt from 'jwt-decode'
import { Icon } from "@mui/material";
import styled from "styled-components";
import ApiAuthService from "../../Api/AuthApi/apiAuthService";
import { alertContent } from "../../utils/utils";
import ApiUserService from "../../Api/ApiUserService/apiUserService";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setValueUser } from "../../store/user";


const ImgDiv = styled.a`
  display: flex;
  flex-direction: row-reverse;
  margin-top:-35px;
  padding-right: 10px;
  z-index: 10;
`;

const signInEmpty = () => {
  let data = {
    mail: '',
    password: ''
  };

  return data;
}

function Login() {
  const [rememberMe, setRememberMe] = useState(false);
  const [signIn, setSignin] = useState(signInEmpty);
  const [error, setError] = useState<string | undefined>(undefined);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [seePassword, setSeePassword] = useState(false);
  const [disable, setDisable] = useState(false);
  const login = () => {
    setDisable(true);
    const api = new ApiAuthService();
    api.postLogin(signIn).then(async(res) => {
      const { accessToken } = res.data;   
      axios.defaults.headers.common = {'Authorization': `Bearer ${accessToken}`}
      localStorage.setItem('userToken', accessToken);
      const apiUser = new ApiUserService();
      const user = await apiUser.getInfoUser();
      dispatch(setValueUser(user.data));
      navigate(`/dashboard`);
    }).catch(e => {
      console.log(e.response.data);
      setError("Email ou mot de passe incorrecte");
    }).finally(() => {
      setDisable(false);
    })
  }
  return (
    <IllustrationLayout
      title="Connexion"
      description="Bienvenue chez tank"
      illustration={center}
    >
      {error ?
        <MDAlert color="primary" dismissible>
          {alertContent(error)}
        </MDAlert>
      :null}
      <MDBox component="form" role="form" style={{padding:"0 15% 0 15%"}}>
        <MDBox mb={2}>
          <MDInput type="email" label="Email" fullWidth onChange={(e:any) => {setSignin(old => ({...old, mail: e.target.value}))}}/>
        </MDBox>
        <MDBox mb={2}>
          <MDInput style={{zIndex:1}} type={!seePassword ? "password" : ""} label="Password" fullWidth onChange={(e:any) => {setSignin(old => ({...old, password: e.target.value}))}}/>
          <ImgDiv  onClick={() => {setSeePassword(!seePassword)}}>
            {
              !seePassword ?
                <Icon style={{zIndex:"10"}} fontSize="medium">visibility-off</Icon>
                :
                <Icon style={{zIndex:"10"}} fontSize="medium"><VisibilityOffIcon/></Icon>
            }
          </ImgDiv>
        </MDBox>
        <MDBox mt={1} textAlign="center">
          <MDTypography variant="button" color="text">
            <MDTypography
              component={Link}
              to="/forget-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              mot de passe oublié 
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mt={2} mb={1}>
          <MDButton variant="gradient" color="info" size="large" fullWidth onClick={login} disabled={disable}>
            Connexion
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}
export default Login;