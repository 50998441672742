import React from 'react'
import { Typography } from '@mui/material'
import data from '../../data/fr.json';
import Header from '../../components/Header';

export default function Ats() {

  return (
    <div>
      <Header title={data.ats} desc={data.descAts}/>
    </div>
  )
}
